import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import { InputLabel } from "./InputLabel";

export const imageModels = {
    "SD XL": "stable-diffusion-xl-1024-v1-0",
    "Flux Schnell": "black-forest-labs/FLUX.1-schnell-Free",
    "SD 3": "sd3",
    "SD 1.6": "stable-diffusion-v1-6",
    // "Octo SD XL": "sdxl",
    // "Octo SD 1.5": "sd",
    // "Amazon Titan": "amazon-titan",
    // "Prodia SD XL": "prodia-xl",
    // "Together SD XL": "together-xl",
    "Google Imagen-2": "imagen2",
    "DALL-E 3": "dall-e-3",
    // "DALL-E 2": "dall-e-2",
    // "Google Pictures": "google-images",
    Unsplash: "unsplash",
};

export const ImageModelSelector = ({ imageModel, onChange, silver }) => {
    let models = { ...imageModels };
    if (!silver) {
        delete models["Google Imagen-2"];
        delete models["DALL-E 3"];
        // delete models["DALL-E 2"];
        delete models["SD 3"];
    }
    return (
        <Grid item xs={6} md={3}>
            <FormControl fullWidth>
                <InputLabel caption="imageModel" />
                <Select variant="outlined" value={imageModel} onChange={(e) => onChange(e.target.value)}>
                    {Object.keys(models).map((model) => (
                        <MenuItem key={model} value={model}>
                            {model}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
};
